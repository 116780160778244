'use client';

import { ReactElement, useState } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

interface Props extends Omit<TooltipProps, 'title' | 'children'> {
  children: ReactElement;
  title?: string | ReactElement;
}

export const TooltipWrapper = ({ title, children, placement = 'top', ...rest }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  // Return children directly if no title is provided
  if (!title) {
    return children; // Return the ReactElement directly
  }

  return (
    <Tooltip
      title={title}
      open={showTooltip}
      placement={placement}
      sx={{ textAlign: 'center' }}
      classes={{ tooltip: 'centered-tooltip' }}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
