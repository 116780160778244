import SweetAlert, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

import { scrollIntoTheView } from '@paytome.co/shared';
import { KeyValuePairs } from '@paytome.co/type';

type IconType = 'success' | 'error' | 'warning' | 'info';

interface ConfirmAlertProps {
  icon?: IconType;
  text?: string;
  confirmButtonText?: string;
  denyButtonText?: string;
}

export const usePopupAlerts = () => {
  // Common custom classes for alerts
  const customClass = {
    actions: 'my-actions',
    cancelButton: 'order-1 right-gap',
    confirmButton: 'order-2',
    denyButton: 'order-3',
    topZIndex: 'height-z-index',
  };

  // Base SweetAlert.fire function with defaults
  const fireAlert = (options: SweetAlertOptions): Promise<SweetAlertResult> => {
    return SweetAlert.fire({
      position: 'center',
      showConfirmButton: true,
      timerProgressBar: true,
      showCloseButton: false,
      allowOutsideClick: false,
      customClass,
      ...options,
    });
  };

  const sendNotification = (iconType: IconType = 'success', message = '', extraOptions?: SweetAlertOptions) => {
    void fireAlert({
      icon: iconType,
      text: message,
      timer: iconType === 'success' ? 2500 : undefined,
      ...extraOptions,
    });
  };

  const smoothScrollNotification = (
    iconType: IconType = 'success',
    message: string,
    targetId: string,
    extraOptions?: SweetAlertOptions
  ) => {
    fireAlert({
      icon: iconType,
      text: message,
      timer: iconType === 'success' ? 2500 : undefined,
      ...extraOptions,
    }).then(() => {
      if (targetId) {
        setTimeout(() => {
          scrollIntoTheView(targetId);
        }, 500);
      }
    });
  };

  const errorNotification = (iconType: IconType = 'error', message = '', extraOptions?: SweetAlertOptions) => {
    sendNotification(iconType, message, extraOptions);
  };

  const confirmAlert = async (attributes: ConfirmAlertProps, extraOptions?: SweetAlertOptions) => {
    const {
      icon = 'info',
      text = 'Do you really want to take this action?',
      confirmButtonText = 'Yes',
      denyButtonText = 'No',
    } = attributes;

    return fireAlert({
      icon,
      text,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText,
      denyButtonText,
      showLoaderOnConfirm: true,
      allowEnterKey: false,
      allowEscapeKey: false,
      ...extraOptions,
    });
  };

  const validationErrorsWithBreak = (errors: KeyValuePairs) => {
    const htmlError = Object.values(errors)
      .map(error => {
        // @ts-expect-error error message will be an array
        return error?.[0];
      })
      .join('<br/>');

    void fireAlert({
      icon: 'error',
      html: htmlError,
      confirmButtonText: 'Ok',
    });

    return false;
  };

  const notifyUser = (iconType: IconType = 'success', message = '', extraOptions?: SweetAlertOptions) => {
    return new Promise((resolve, reject) => {
      fireAlert({
        icon: iconType,
        text: message,
        timer: iconType === 'success' ? 2500 : undefined,
        ...extraOptions,
      })
        .then(resolve)
        .catch(reject);
    });
  };

  return {
    notifyUser,
    confirmAlert,
    sendNotification,
    errorNotification,
    smoothScrollNotification,
    validationErrorsWithBreak,
  };
};
