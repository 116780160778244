'use client';

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { muiThemeConfig } from '@paytome.co/shared';
import { MuiSettings } from '@paytome.co/type';

// ** Context Types
interface SettingsContextType {
  settings: MuiSettings;
  saveSettings: (updatedSettings: MuiSettings) => void;
}

// ** Props for Provider
interface SettingsProviderProps {
  children: ReactNode;
  pageSettings?: Partial<MuiSettings>;
}

// ** Initial Settings
const initialSettings: MuiSettings = {
  themeColor: 'primary',
  mode: muiThemeConfig.mode,
  skin: muiThemeConfig.skin,
  footer: muiThemeConfig.footer,
  layout: muiThemeConfig.layout,
  lastLayout: muiThemeConfig.layout,
  direction: muiThemeConfig.direction,
  navHidden: muiThemeConfig.navHidden,
  appBarBlur: muiThemeConfig.appBarBlur,
  navCollapsed: true,
  contentWidth: muiThemeConfig.contentWidth,
  toastPosition: muiThemeConfig.toastPosition,
  verticalNavToggleType: muiThemeConfig.verticalNavToggleType,
  appBar:
    muiThemeConfig.layout === 'horizontal' && muiThemeConfig.appBar === 'hidden' ? 'fixed' : muiThemeConfig.appBar,
};

const staticSettings: Partial<MuiSettings> = {
  appBar: initialSettings.appBar,
  footer: initialSettings.footer,
  layout: initialSettings.layout,
  navHidden: initialSettings.navHidden,
  lastLayout: initialSettings.lastLayout,
  toastPosition: initialSettings.toastPosition,
  navCollapsed: initialSettings.navCollapsed,
};

// ** Restore Settings Function
const restoreSettings = (): MuiSettings => {
  let settings: {
    navHidden?: boolean;
    lastLayout?: string;
    footer?: string;
    skin?: string;
    toastPosition?: string;
    navCollapsed: boolean;
    mode?: string;
    layout?: string;
    contentWidth?: string;
    themeColor?: string;
    appBarBlur?: boolean;
    appBar?: string;
    verticalNavToggleType?: string;
    direction?: string;
  } = initialSettings;
  try {
    const storedData = localStorage.getItem('settings');
    if (storedData) {
      const parsedData = JSON.parse(storedData) as Partial<MuiSettings>;
      settings = {
        ...parsedData,
        ...staticSettings,
        navCollapsed: [true, false].includes(parsedData?.navCollapsed || false)
          ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            parsedData.navCollapsed!
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            staticSettings.navCollapsed!,
      };
    }
  } catch (err) {
    console.error(err);
  }

  return settings as MuiSettings;
};

// ** Store Settings Function
const storeSettings = (settings: Partial<MuiSettings>): void => {
  const initSettings = { ...settings };
  delete initSettings.appBar;
  delete initSettings.footer;
  delete initSettings.layout;
  delete initSettings.navHidden;
  delete initSettings.lastLayout;
  delete initSettings.toastPosition;
  localStorage.setItem('settings', JSON.stringify(initSettings));
};

// ** Create Context
export const SettingsContext = createContext<SettingsContextType>({
  settings: initialSettings,
  saveSettings: () => null,
});

// ** Provider Component
export const SettingsProvider = ({ children, pageSettings }: SettingsProviderProps) => {
  const [settings, setSettings] = useState<MuiSettings>({ ...initialSettings });

  useEffect(() => {
    const restoredSettings = restoreSettings();
    setSettings({ ...restoredSettings });

    if (pageSettings) {
      setSettings(prev => ({ ...prev, ...pageSettings }));
    }
  }, [pageSettings]);

  useEffect(() => {
    if (settings.layout === 'horizontal' && settings.mode === 'semi-dark') {
      saveSettings({ ...settings, mode: 'light' });
    }
    if (settings.layout === 'horizontal' && settings.appBar === 'hidden') {
      saveSettings({ ...settings, appBar: 'fixed' });
    }
  }, [settings, settings.layout]);

  const saveSettings = (updatedSettings: MuiSettings): void => {
    storeSettings(updatedSettings);
    setSettings(updatedSettings);
  };

  return <SettingsContext.Provider value={{ settings, saveSettings }}>{children}</SettingsContext.Provider>;
};

export const SettingsConsumer = SettingsContext.Consumer;

export const useSettings = (): SettingsContextType => useContext(SettingsContext);
