import Image from 'next/image';
import Link from 'next/link';
import { ComponentProps } from 'react';

import { Logo } from '@paytome.co/icon';
import { cn } from '@paytome.co/lib';
import { LoginForm } from '@paytome.co/mui/components/auth';
import { CONFIG } from '@paytome.co/shared';

type Props = ComponentProps<'div'>;

export default function Login({ className, ...rest }: Props) {
  return (
    <div className={cn('container', className)} {...rest}>
      <div className='py-4'>
        <div className='flex items-center justify-center gap-8'>
          <div className={'hidden max-w-[450px] lg:block'}>
            <Image priority width={667} height={608} alt='login' src={'/assets/images/pages/auth/login.png'} />
          </div>
          <div className={'max-w-[400px]'}>
            <Link target={'_blank'} href={CONFIG.publicUrl}>
              <Logo className={'mb-4'} />
            </Link>
            <div className={'mb-6'}>
              <h2 className={'mb-2 text-xl font-semibold'}>Welcome to {CONFIG.appName}!</h2>
              <p className={'text-gray-500'}>Please sign-in to your account and start the adventure.</p>
            </div>
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}
