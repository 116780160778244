import { Fragment } from 'react';

import { cn } from '@paytome.co/lib';
import { HelperText, Validations } from '@paytome.co/shared';

type Props = { validations: Validations };

export const ShowPasswordValidation = ({ validations }: Props) => {
  if (!validations?.length) return;

  return (
    <Fragment key={validations.length}>
      {validations?.map((validation, index) => {
        return (
          <HelperText
            key={validation?.id || index}
            message={validation.message}
            hasError={validation.hasError}
            className={cn('mb-1', { 'mb-0': index === validations?.length - 1 })}
          />
        );
      })}
    </Fragment>
  );
};
