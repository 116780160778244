'use client';

import { ReactNode } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Theme } from '@mui/material/styles';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { MuiGlobalStyles } from '@paytome.co/mui';
import { muiThemeConfig } from '@paytome.co/shared';
import { MuiSettings } from '@paytome.co/type';

import overrides from './overrides';
import MuiThemeOptions from './theme-options';
import typography from './typography';

interface Props {
  settings: MuiSettings;
  children: ReactNode;
}

type ExtendedColors =
  | 'mainColor'
  | 'dark'
  | 'light'
  | 'lightPaperBg'
  | 'darkPaperBg'
  | 'bodyBg'
  | 'trackBg'
  | 'tooltipBg'
  | 'tableHeaderBg';

type CustomColors = Record<ExtendedColors, string>;

declare module '@mui/material/styles' {
  interface Palette {
    customColors: CustomColors;
  }

  interface PaletteOptions {
    customColors: CustomColors;
  }
}

export const MuiThemeProvider = (props: Props) => {
  // ** Props
  const { settings, children } = props;

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = MuiThemeOptions(settings);

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig);

  // ** Deep Merge Component overrides of core and user
  const mergeComponentOverrides = (theme: Theme, settings: MuiSettings) =>
    deepmerge({ ...overrides(theme, settings) }, {});

  // ** Deep Merge Typography of core and user
  const mergeTypography = (theme: Theme) => deepmerge(typography(theme), {});

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    components: { ...mergeComponentOverrides(theme, settings) },
    typography: { ...mergeTypography(theme) },
  });

  // ** Set responsive font sizes to true
  if (muiThemeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles styles={() => MuiGlobalStyles(theme)} />
      {children}
    </ThemeProvider>
  );
};
