'use client';

import { useRef, useState } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import List from '@mui/material/List';
import { styled, useTheme } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { hexToRGBA } from '@paytome.co/lib';
import { muiThemeConfig } from '@paytome.co/shared';
import { KeyValuePairs } from '@paytome.co/type';

import { MuiLayoutProps } from '../types';
import Drawer from './Drawer';

// import VerticalNavHeader from './VerticalNavHeader';
// import VerticalNavItems from './VerticalNavItems';

interface Props {
  navWidth: number;
  navVisible: boolean;
  collapsedNavWidth: number;
  hidden: MuiLayoutProps['hidden'];
  navigationBorderWidth: number;
  toggleNavVisibility: () => void;
  settings: MuiLayoutProps['settings'];
  children: MuiLayoutProps['children'];
  setNavVisible: (value: boolean) => void;
  saveSettings: MuiLayoutProps['saveSettings'];
  navMenuContent: MuiLayoutProps['verticalLayoutProps']['navMenu']['content'];
  navMenuBranding: MuiLayoutProps['verticalLayoutProps']['navMenu']['branding'];
  menuLockedIcon: MuiLayoutProps['verticalLayoutProps']['navMenu']['lockedIcon'];
  verticalNavItems: MuiLayoutProps['verticalLayoutProps']['navMenu']['navItems'];
  navMenuProps: MuiLayoutProps['verticalLayoutProps']['navMenu']['componentProps'];
  menuUnlockedIcon: MuiLayoutProps['verticalLayoutProps']['navMenu']['unlockedIcon'];
  afterNavMenuContent: MuiLayoutProps['verticalLayoutProps']['navMenu']['afterContent'];
  beforeNavMenuContent: MuiLayoutProps['verticalLayoutProps']['navMenu']['beforeContent'];
}

const StyledBoxForShadow = styled(Box)<BoxProps>(({ theme }) => ({
  top: 72,
  zIndex: 2,
  opacity: 0,
  width: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  height: theme.mixins.toolbar.minHeight,
  transition: 'opacity .15s ease-in-out',
  '&.scrolled': {
    opacity: 1,
  },
}));

const Navigation = (props: Props) => {
  // ** Props
  const { hidden, settings, afterNavMenuContent, beforeNavMenuContent, navMenuContent: userNavMenuContent } = props;

  // ** States
  const [navHover, setNavHover] = useState<boolean>(false);
  // const [groupActive, setGroupActive] = useState<string[]>([]);
  // const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([]);

  // ** Ref
  const shadowRef = useRef(null);

  // ** Hooks
  const theme: KeyValuePairs = useTheme();
  const { mode } = settings;

  // ** Var
  const { afterVerticalNavMenuContentPosition, beforeVerticalNavMenuContentPosition } = muiThemeConfig;

  // ** Fixes Navigation InfiniteScroll
  const handleInfiniteScroll = (ref: HTMLElement) => {
    if (ref) {
      // @ts-expect-error TS2551: Property _getBoundingClientRect does not exist on type HTMLElement. Did you mean getBoundingClientRect?
      // lib. dom. d. ts(7844, 5): getBoundingClientRect is declared here.
      ref._getBoundingClientRect = ref.getBoundingClientRect;

      ref.getBoundingClientRect = () => {
        // @ts-expect-error TS2551: Property _getBoundingClientRect does not exist on type HTMLElement. Did you mean getBoundingClientRect?
        const original = ref._getBoundingClientRect();

        return { ...original, height: Math.floor(original.height) };
      };
    }
  };

  // ** Scroll Menu
  const scrollMenu = (container: KeyValuePairs) => {
    if (beforeVerticalNavMenuContentPosition === 'static' || !beforeNavMenuContent) {
      container = hidden ? container.target : container;
      if (shadowRef && container.scrollTop > 0) {
        // @ts-expect-error can be null
        if (!shadowRef.current.classList.contains('scrolled')) {
          // @ts-expect-error can be null
          shadowRef.current.classList.add('scrolled');
        }
      } else {
        // @ts-expect-error can be null
        shadowRef.current.classList.remove('scrolled');
      }
    }
  };

  const shadowBgColor = () => {
    if (mode === 'semi-dark') {
      return `linear-gradient(${theme.palette.customColors.darkPaperBg} 5%,${hexToRGBA(
        theme.palette.customColors.darkPaperBg,
        0.85
      )} 30%,${hexToRGBA(theme.palette.customColors.darkPaperBg, 0.5)} 65%,${hexToRGBA(
        theme.palette.customColors.darkPaperBg,
        0.3
      )} 75%,transparent)`;
    } else {
      return `linear-gradient(${theme.palette.background.paper} 5%,${hexToRGBA(
        theme.palette.background.paper,
        0.85
      )} 30%,${hexToRGBA(theme.palette.background.paper, 0.5)} 65%,${hexToRGBA(
        theme.palette.background.paper,
        0.3
      )} 75%,transparent)`;
    }
  };

  const ScrollWrapper = hidden ? Box : PerfectScrollbar;

  return (
    <Drawer {...props} navHover={navHover} setNavHover={setNavHover}>
      {/*<VerticalNavHeader {...props} navHover={navHover} />*/}
      {beforeNavMenuContent && beforeVerticalNavMenuContentPosition === 'fixed' ? beforeNavMenuContent(props) : null}
      {(beforeVerticalNavMenuContentPosition === 'static' || !beforeNavMenuContent) && (
        <StyledBoxForShadow ref={shadowRef} sx={{ background: shadowBgColor() }} />
      )}
      <Box sx={{ position: 'relative', overflow: 'hidden' }}>
        {/* @ts-expect-error TS2604: JSX element type ScrollWrapper does not have any construct or call signatures. */}
        <ScrollWrapper
          {...(hidden
            ? {
                onScroll: (container: KeyValuePairs) => scrollMenu(container),
                sx: { height: '100%', overflowY: 'auto', overflowX: 'hidden' },
              }
            : {
                options: { wheelPropagation: false },
                onScrollY: (container: KeyValuePairs) => scrollMenu(container),
                containerRef: (ref: KeyValuePairs) => handleInfiniteScroll(ref),
              })}
        >
          {beforeNavMenuContent && beforeVerticalNavMenuContentPosition === 'static'
            ? beforeNavMenuContent(props)
            : null}
          {userNavMenuContent ? (
            userNavMenuContent(props)
          ) : (
            <List className='nav-items' sx={{ py: 1, '& > :first-child': { mt: '0' } }}>
              {/*<VerticalNavItems
                navHover={navHover}
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
                {...props}
              />*/}
            </List>
          )}
          {afterNavMenuContent && afterVerticalNavMenuContentPosition === 'static' ? afterNavMenuContent(props) : null}
        </ScrollWrapper>
      </Box>
      {afterNavMenuContent && afterVerticalNavMenuContentPosition === 'fixed' ? afterNavMenuContent(props) : null}
    </Drawer>
  );
};

export default Navigation;
