'use client';

import { ComponentProps } from 'react';

import { yupResolver } from 'packages/mui/src/resolver';
import { useForm } from 'react-hook-form';

import { usePopupAlerts } from '@paytome.co/hooks';
import { ChevronLeft } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';
import { FormControlTextInput } from '@paytome.co/mui';
import { ForgotPasswordSchema, useAuth } from '@paytome.co/mui/components/auth';
import { Button, PrimaryLink } from '@paytome.co/shared';

type Props = ComponentProps<'form'>;

export default function ForgotPasswordForm({ className, ...rest }: Props) {
  const { forgot } = useAuth();
  const { sendNotification } = usePopupAlerts();

  const {
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const onSubmit = (values: { email: string }) => {
    const { email } = values;

    forgot
      .mutateAsync({
        email: email,
      })
      .then(res => {
        if (res.statusCode === 200) {
          sendNotification('success', res.data.message);
          setValue('email', '');
          // router.push({pathname: '/reset-password', query: {token: "sample token", email: `${forgotPasswordRef.current.value}`}})
        } else if (res.statusCode === 400) {
          const errorMessage = res?.data?.errors?.email[0];
          sendNotification('error', errorMessage);
          setError('email', { type: 'custom', message: errorMessage });
        } else {
          const errorMessage = res?.data?.errors?.email([0]) || res?.data?.message;
          sendNotification('error', errorMessage);
        }
      });
  };

  return (
    <form className={cn('', className)} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} {...rest}>
      <FormControlTextInput
        autoFocus={true}
        control={control}
        errors={errors}
        name={'email'}
        label={'Email*'}
        placeholder={'john@example.com'}
      />

      <Button loading={forgot.isPending} type='submit' variant={'secondary'} className={'my-4 w-full'}>
        Send reset link
      </Button>

      <div className={'flex items-center justify-center gap-1'}>
        <ChevronLeft className={'size-5'} />
        <PrimaryLink href='/login'>Back to login</PrimaryLink>
      </div>
    </form>
  );
}
