import { Ref, forwardRef } from 'react';

import MuiAvatar from '@mui/material/Avatar';
import { lighten, useTheme } from '@mui/material/styles';

import { UseBgColorType, useBgColor } from '@paytome.co/mui';
import { ThemeColor } from '@paytome.co/type';

import { PrimaryAvatarProps } from './types';

export const PrimaryAvatar = forwardRef((props: PrimaryAvatarProps, ref: Ref<never>) => {
  // ** Props
  const { sx, src, skin = 'filled', color = 'primary' } = props;

  // ** Hook
  const theme = useTheme();
  const bgColors: UseBgColorType = useBgColor();

  const getAvatarStyles = (skin: 'filled' | 'light' | 'light-static' | undefined, skinColor: ThemeColor) => {
    let avatarStyles;

    if (skin === 'light') {
      avatarStyles = { ...bgColors[`${skinColor}Light`] };
    } else if (skin === 'light-static') {
      avatarStyles = {
        color: bgColors[`${skinColor}Light`].color,
        backgroundColor: lighten(theme.palette[skinColor].main, 0.88),
      };
    } else {
      avatarStyles = { ...bgColors[`${skinColor}Filled`] };
    }

    return avatarStyles;
  };

  const colors: UseBgColorType = {
    primary: getAvatarStyles(skin, 'primary'),
    secondary: getAvatarStyles(skin, 'secondary'),
    success: getAvatarStyles(skin, 'success'),
    error: getAvatarStyles(skin, 'error'),
    warning: getAvatarStyles(skin, 'warning'),
    info: getAvatarStyles(skin, 'info'),
  };

  return <MuiAvatar ref={ref} {...props} sx={!src && skin && color ? Object.assign(colors[color], sx) : sx} />;
});
