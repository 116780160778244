import { sendRequest } from '@paytome.co/shared';

const ROUTES = {
  login: '/auth/login',
  logout: '/auth/logout',
  forgotPass: '/auth/forgot-password',
  register: '/auth/register',
  resetPass: '/auth/reset-password',
  otpVerify: '/login-otp-verify',
  userInfo: '/profile/my-account',
};

export const getUserInfo = (payload = {}, queryParams = '') => {
  return sendRequest({ url: ROUTES.userInfo, payload, queryParams });
};

export const loginToTheApp = (payload = {}, queryParams = '') => {
  return sendRequest({ method: 'POST', url: ROUTES.login, payload, queryParams, willRedirect: false });
};

export const forgotPassword = (payload = {}, queryParams = '') => {
  return sendRequest({ method: 'POST', url: ROUTES.forgotPass, payload, queryParams, willRedirect: false });
};

export const registerToApp = (payload = {}, queryParams = '') => {
  return sendRequest({ method: 'POST', url: ROUTES.register, payload, queryParams, willRedirect: false });
};

export const resetPassword = (payload = {}, queryParams = '') => {
  return sendRequest({ method: 'POST', url: ROUTES.resetPass, payload, queryParams, willRedirect: false });
};

export const otpVerification = (payload = {}, queryParams = '') => {
  return sendRequest({ method: 'PATCH', url: ROUTES.otpVerify, payload, queryParams, willRedirect: false });
};

export const logoutFromTheApp = (payload = {}, queryParams = '') => {
  return sendRequest({ method: 'POST', url: ROUTES.logout, payload, queryParams, willRedirect: false });
};
