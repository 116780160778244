'use client';

import { BaseSyntheticEvent, ComponentProps, Suspense, useState } from 'react';

import { Checkbox, FormControl, FormControlLabel, IconButton } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { usePopupAlerts } from '@paytome.co/hooks';
import { Eye, EyeOff } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';
import { FormControlTextInput } from '@paytome.co/mui';
import { Login2faModal, RegistrationSchema, ShowPasswordValidation, useAuth } from '@paytome.co/mui/components/auth';
import { yupResolver } from '@paytome.co/mui/resolver';
import { Button, CONFIG, CustomPhoneInput, HelperText, PrimaryLink } from '@paytome.co/shared';
import { DefaultValues } from '@paytome.co/shared/constants';
import { Subscription } from '@paytome.co/type';

interface Props extends ComponentProps<'form'> {
  subscription: Subscription;
}

const action_name = 'signup';

export function RegisterForm({ className, subscription, ...rest }: Props) {
  const { register } = useAuth();
  const { sendNotification } = usePopupAlerts();
  const [showPassword, setShowPassword] = useState(false);
  const [phoneInfo, setPhoneInfo] = useState(DefaultValues.phoneInfo);
  const [show2fa, setShow2fa] = useState(false);
  const [loginResponse, setLoginResponse] = useState({});
  const [sentToInfo, setSentToInfo] = useState({
    email: '',
    phone: '',
  });

  const PasswordIcon = showPassword ? Eye : EyeOff;

  const {
    watch,
    control,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone: '',
      terms: false,
    },
    resolver: yupResolver(RegistrationSchema),
    mode: 'onSubmit',
  });

  const passwordValidations = [
    {
      id: 1,
      hasError: watch('password').length < 8,
      message: 'X Use 8 or More Characters',
    },
    {
      id: 2,
      hasError: !watch('password').match(/(?=.*[a-z])(?=.*[A-Z])/),
      message: 'X Use Upper and Lower-Case Letters (e.g. Aa)',
    },
    {
      id: 3,
      hasError: !watch('password').match(/(\d)/),
      message: 'X Use a Number (e.g. 1234)',
    },
    {
      id: 4,
      hasError: !watch('password').match(/(\W)/),
      message: 'X Use a Symbol (e.g. ! @ # $)',
    },
  ];

  const onSubmit = async (
    data: { first_name: string; last_name: string; email: string; password: string },
    event?: BaseSyntheticEvent<object>
  ) => {
    if (Object.keys(errors).length > 0) {
      if (errors?.email) {
        setError('email', { type: 'custom', message: 'The email has already been taken.' });
      }
      if (errors?.phone) {
        setError('phone', { type: 'custom', message: 'The mobile number has already been taken.' });
      }
      event?.preventDefault();
      return;
    }

    const { first_name, last_name, email, password } = data;

    await register
      .mutateAsync({
        email: email,
        password: password,
        plan_token: subscription?.token || '',
        last_name: last_name.trim(),
        first_name: first_name.trim(),
        password_confirmation: password,
        phone_country_iso2: phoneInfo?.iso2,
        mobile_number: phoneInfo?.phone_number,
      })
      .then(res => {
        if (res.statusCode === 422) {
          const errors = res?.data?.errors;
          if (errors?.email) {
            setError('email', { type: 'required', message: errors.email[0] });
          }
          if (errors?.mobile_number) {
            setError('phone', { type: 'required', message: errors.mobile_number[0] });
          }
        } else if (res.statusCode === 412) {
          sendNotification('error', res?.data?.message);
        } else if (res.statusCode === 301 && res?.data?.status) {
          const resData = res?.data || {};
          setSentToInfo({
            ...sentToInfo,
            email: res?.data?.sent_to_email ?? '',
            phone: res?.data?.sent_to_phone ?? '',
          });
          setShow2fa(true);
          setLoginResponse({
            ...resData,
            email,
            password,
            action_name,
          });
        } else {
          sendNotification('error', res?.data?.message || 'Sorry! something went wrong.');

          // GoToDirectory('/register')
        }
      });
  };

  return (
    <>
      {show2fa && (
        <Suspense>
          <Login2faModal
            sentToInfo={sentToInfo}
            show2fa={show2fa}
            setShow2fa={setShow2fa}
            loginResponse={loginResponse}
          />
        </Suspense>
      )}
      <form className={cn('', className)} noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} {...rest}>
        <div className={'space-y-4'}>
          <FormControlTextInput
            autoFocus
            control={control}
            errors={errors}
            name={'first_name'}
            label={'First Name*'}
            placeholder='John'
            autoComplete='given-name'
          />

          <FormControlTextInput
            control={control}
            errors={errors}
            name='last_name'
            label='Last Name*'
            placeholder='Doe'
            autoComplete='family-name'
          />

          <FormControlTextInput
            control={control}
            errors={errors}
            name='email'
            label='Email*'
            placeholder='user@email.com'
            autoComplete='off'
          />

          <CustomPhoneInput
            required
            name={'phone'}
            errors={errors}
            control={control}
            setValue={setValue}
            phoneInfo={phoneInfo}
            setPhoneInfo={setPhoneInfo}
          />

          <div className={'relative'}>
            <FormControlTextInput
              autoComplete={'new-password'}
              placeholder={'********'}
              control={control}
              errors={errors}
              name='password'
              label='Password*'
              willShowError={false}
              type={showPassword ? 'text' : 'password'}
              sx={{ 'input.MuiInputBase-input': { paddingRight: '2.5rem !important' } }}
            />

            <div className={'absolute right-4 top-0.5'}>
              <IconButton
                edge='end'
                onMouseDown={e => e.preventDefault()}
                onClick={() => setShowPassword(!showPassword)}
              >
                <PasswordIcon aria-label={'show-hide-password'} className='size-5 text-gray-400' />
              </IconButton>
            </div>
          </div>
          <div className={'mt-1'}>
            <ShowPasswordValidation validations={passwordValidations} />
          </div>
        </div>

        <FormControl sx={{ my: 1.5 }} error={Boolean(errors.terms)}>
          <Controller
            name='terms'
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControlLabel
                  sx={{
                    ...(errors.terms ? { color: 'error.main' } : null),
                    '& .MuiFormControlLabel-label': {
                      fontSize: '0.875rem',
                      color: 'text.secondary',
                    },
                  }}
                  control={
                    <Checkbox
                      name='terms'
                      checked={value}
                      onChange={onChange}
                      className={cn('', { '[&_svg]:fill-red-primary': errors.terms })}
                    />
                  }
                  label={
                    <>
                      <span
                        className={cn('text-gray-medium mr-1 font-semibold', {
                          'text-red-strong': errors.terms,
                        })}
                      >
                        I agree to
                      </span>
                      <PrimaryLink target={'_blank'} href={`${CONFIG.publicUrl}/privacy-policy`}>
                        privacy policy & terms
                      </PrimaryLink>
                    </>
                  }
                />
              );
            }}
          />
          <HelperText hasError={!!errors.terms} message={errors?.terms?.message} />
        </FormControl>

        <Button type='submit' loading={register.isPending} variant={'secondary'} className={'mb-4 w-full'}>
          Register
        </Button>

        <div className={'flex flex-wrap items-center justify-center'}>
          <p className={'text-gray-medium mr-2 font-medium'}>Already have an account?</p>

          <PrimaryLink href={'/login'}>Sign in instead</PrimaryLink>
        </div>
      </form>
    </>
  );
}
