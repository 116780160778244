import { FieldValues, UseFormSetError } from 'react-hook-form';

import { usePopupAlerts } from '../sweet-alert';

interface ErrorObject {
  type: string;
  message: string;
}

interface ErrorDictionary {
  [key: string]: ErrorObject;
}

const generateErrorHTML = (
  errors: ErrorDictionary,
  setError?: (
    field: string,
    message: {
      type: string;
      message: string;
    }
  ) => UseFormSetError<FieldValues>
): string => {
  let errorHTML = '';
  for (const field in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const error = errors[field];

      if (Array.isArray(error)) {
        // Handle array of error messages
        // eslint-disable-next-line no-loop-func
        error.forEach((errorMessage: string) => {
          if (setError) {
            setError(field, { type: 'manual', message: errorMessage });
          }

          errorHTML += `${errorMessage}<br>`;
        });
      } else {
        // Handle single error object
        errorHTML += `${error.message}<br>`;
      }
    }
  }

  return errorHTML;
};

export const useErrorValidation = () => {
  const { sendNotification } = usePopupAlerts();

  const validateErrors = (
    errors: ErrorDictionary,
    setError?: (
      field: string,
      message: {
        type: string;
        message: string;
      }
    ) => UseFormSetError<FieldValues>
  ) => {
    const htmlErrors = generateErrorHTML(errors, setError);

    sendNotification('error', '', {
      html: `<div class="w-full flex justify-center space-y-1"><p class="text-sm font-normal text-red-strong text-left">${htmlErrors}</p></div>`,
    });
  };

  return { validateErrors };
};
