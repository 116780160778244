import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';

import { useBgColor } from '@paytome.co/mui';
import { ThemeColor } from '@paytome.co/type';

interface BadgeProps extends MuiBadgeProps {
  skin?: 'light' | 'dark'; // assuming 'light' or 'dark' for skin
  color?: ThemeColor;
}

export const PrimaryBadge = (props: BadgeProps) => {
  // ** Props
  const { sx, skin, color } = props;

  // ** Hook
  const bgColors = useBgColor();

  const colors = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  return (
    <MuiBadge
      {...props}
      sx={skin === 'light' && color ? Object.assign({ '& .MuiBadge-badge': colors[color] }, sx) : sx}
    />
  );
};
