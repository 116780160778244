import Link from 'next/link';
import { BaseSyntheticEvent, useState } from 'react';

import { Badge, CircularProgress, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';

import { usePopupAlerts } from '@paytome.co/hooks';
import { Power, User } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';
import { useAccountInfo, useAuth } from '@paytome.co/mui/components/auth';
import { CONFIG, goToDirectory, removeCookies } from '@paytome.co/shared';
import { MuiSettings } from '@paytome.co/type';

import UserAvatar from './user-avatar';

const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const profileUrl = `${CONFIG.myAccountUrl}/my-profile/account`;

const UserDropdown = ({ settings }: { settings: MuiSettings }) => {
  const { logout } = useAuth();
  const { user } = useAccountInfo();
  const { sendNotification } = usePopupAlerts();
  const [anchorEl, setAnchorEl] = useState(null);

  const avatar = user?.avatar || {
    alt: '',
    img: '',
    icon: '',
    text: '',
    color: '',
  };

  const { direction } = settings;

  const handleDropdownOpen = (event: BaseSyntheticEvent) => setAnchorEl(event.currentTarget);

  const handleDropdownClose = () => setAnchorEl(null);

  const clearUserData = () => {
    const redirectTo = CONFIG.authUrl + `/login?rt=${CONFIG.vendorUrl}`;
    localStorage.removeItem('userData');
    localStorage.removeItem('current_plan_token');
    removeCookies([CONFIG.authToken]);
    goToDirectory(redirectTo);
  };

  const handleLogout = async () => {
    try {
      await logout.mutateAsync({}).then((res: { statusCode: number }) => {
        if (res.statusCode === 202) {
          clearUserData();
        } else {
          sendNotification('error', 'Logout Failed.');
          goToDirectory(`/`);
        }
      });
    } catch (err) {
      console.log(`logout error ${err}`);
      sendNotification('error', 'An error occurred during logout.');
    } finally {
      handleDropdownClose();
    }
  };

  return (
    <>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <UserAvatar avatar={avatar} />
      </Badge>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleDropdownClose}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <div className='divide-y divide-gray-300'>
          {/* User Info */}
          <li className='px-4 py-2'>
            <div className='flex items-center'>
              <Badge
                overlap='circular'
                badgeContent={<BadgeContentSpan />}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <UserAvatar avatar={avatar} sx={{ width: '2.5rem', height: '2.5rem' }} />
              </Badge>
              <div className='ml-3 flex flex-col'>
                <p
                  title={`${user?.first_name} ${user?.last_name}`}
                  className='max-w-[143px] truncate font-medium text-gray-600'
                >
                  {user?.first_name} {user?.last_name}
                </p>
                <p className='text-gray-medium text-sm'>{user?.account_number}</p>
              </div>
            </div>
          </li>

          {/* Profile Link */}
          <li className='px-4 py-2' onClick={handleDropdownClose}>
            <Link href={profileUrl} target={'_blank'}>
              <div className='[&_svg]:text-gray-medium flex w-full items-center gap-2 [&_svg]:text-xl'>
                <User />
                <div className='flex flex-col'>
                  <p className='font-medium text-gray-600'>My Profile</p>
                  <p className='text-gray-medium text-sm'>{user?.plan?.name}</p>
                </div>
              </div>
            </Link>
          </li>

          {/* Logout */}
          <li
            className={cn('cursor-pointer px-4 py-2', { 'cursor-not-allowed': logout.isPending })}
            onClick={logout.isPending ? e => e.preventDefault() : handleLogout}
          >
            <div className={'[&_svg]:text-gray-medium flex w-full items-center gap-2 [&_svg]:text-xl'}>
              {logout.isPending ? <CircularProgress size={20} /> : <Power />}

              <p className={cn('font-medium text-gray-600', { 'text-gray-400': logout.isPending })}>Sign Out</p>
            </div>
          </li>
        </div>
      </Menu>
    </>
  );
};

export default UserDropdown;
