import Link from 'next/link';
import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { cn } from '@paytome.co/lib';
import { Button, CONFIG } from '@paytome.co/shared';
import { Subscription } from '@paytome.co/type';

import '@paytome.co/style/auth/auth.scss';

type Props = {
  subscription: Subscription;
  className?: string;
};

export function EditSubscription({ subscription, className }: Props) {
  const editUrl = `${CONFIG.publicUrl}/pricing?prev_plan=${subscription?.token}`;

  return (
    <div className={cn('my-2 flex items-center justify-between', className)}>
      <RadioGroup row aria-label='colored' name='colored' defaultValue={subscription?.token}>
        <FormControlLabel
          key={subscription?.token}
          value={subscription?.token}
          label={
            <p
              className={cn('font-semibold')}
              style={{
                color: subscription?.color || '#444',
              }}
            >
              {subscription?.name}
            </p>
          }
          control={<Radio />}
        />
      </RadioGroup>

      <div className={cn('registration-plan-pricing', 'items-center !gap-1')}>
        <p
          className={cn('registration-plan-pricing--rate')}
          style={{
            color: subscription?.color || '#444',
          }}
        >
          ${subscription?.plan_price?.human || 0}
        </p>
        <p className={'registration-plan-pricing--tariff'}>/ {subscription?.type?.replace('ly', '')}</p>
      </div>

      <Link href={editUrl}>
        <Button
          className={
            'registration-plan-pricing--edit-btn max-w-[64px] bg-gray-500 text-sm font-medium hover:bg-indigo-600'
          }
        >
          EDIT
        </Button>
      </Link>
    </div>
  );
}
