'use client';

import { useContext } from 'react';
import { ReactNode, createContext } from 'react';

import { CircularProgress } from '@mui/material';

import { useUserInfo } from '@paytome.co/mui/components/auth';
import { UserData } from '@paytome.co/type';

interface Values {
  user: UserData;
  isLoading: boolean;
}

const defaultProvider = {
  user: null as unknown as UserData,
  isLoading: false,
};

const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { data, isLoading } = useUserInfo();

  const values: Values = {
    user: data?.data?.data as UserData,
    isLoading: isLoading,
  };

  if (isLoading) {
    return (
      <div className={'grid h-screen place-items-center'}>
        <CircularProgress className={'text-blue-primary'} />
      </div>
    );
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };

export const useAccountInfo = () => useContext(AuthContext);
