import React from 'react';

import { Avatar, SxProps } from '@mui/material';

import { getInitials } from '@paytome.co/lib';

type AvatarProps = {
  alt: string;
  icon: string;
  text: string;
  color: string;
  img: string | null;
  background: string;
};

interface UserAvatarProps {
  avatar: AvatarProps;
  sx?: SxProps;
}

export default function UserAvatar({ avatar }: UserAvatarProps) {
  const { alt, img, icon, text, color, background } = avatar;

  if (img) {
    return <Avatar alt={alt} src={img} />;
  }

  if (icon) {
    return <Avatar>{icon}</Avatar>;
  }

  return (
    <Avatar
      sx={{ color: color || '#696CFF', background: background || '#696cff29', fontSize: '.875rem', fontWeight: 500 }}
    >
      {getInitials(text)}
    </Avatar>
  );
}
