'use client';

import React, { useLayoutEffect, useRef } from 'react';

import { TextField, TextFieldProps } from '@mui/material';

import { KeyValuePairs } from '@paytome.co/type';

import { TooltipWrapper } from '../tooltip';

interface PrimaryTextFieldProps extends Omit<TextFieldProps<'outlined'>, 'variant'> {
  tooltipText?: string;
  helperText?: string | boolean | KeyValuePairs;
}

export const PrimaryTextField = ({
  sx,
  value,
  disabled,
  tooltipText,
  helperText,
  autoFocus,
  ...rest
}: PrimaryTextFieldProps) => {
  const hasError = rest.error ?? false;
  const error_message = typeof helperText === 'string' ? helperText : helperText?.message || '';

  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const emailInput = inputRef.current;
    if (emailInput) {
      // Detect autofill using CSS
      const checkAutofill = () => {
        const isAutofilled = emailInput.matches(':-webkit-autofill');

        if (!isAutofilled && inputRef.current && autoFocus) {
          inputRef.current.focus();
        }
      };

      // Observe an input element for autofill changes
      const observer = new MutationObserver(checkAutofill);
      observer.observe(emailInput, { attributes: true, attributeFilter: ['value'] });

      // Initial check
      checkAutofill();

      return () => observer.disconnect();
    }
  }, [autoFocus]);

  return (
    <>
      <TooltipWrapper title={tooltipText}>
        <TextField
          inputRef={inputRef}
          sx={{
            fieldset: {
              borderColor: hasError ? '#f00 !important' : '#8A42C6 !important',
            },
            '.MuiInputLabel-root.Mui-disabled.MuiInputLabel-shrink': {
              color: hasError ? '#f00 !important' : '#8A42C6 !important',
            },
            input: {
              padding: '10px 14px !important',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'var(--font-inter), sans-serif',
              cursor: disabled ? 'not-allowed' : 'auto',
            },
            '.MuiInputBase-input': {
              fontSize: 14,
              fontWeight: 500,
              fontFamily: 'var(--font-inter), sans-serif',
            },
            '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-sizeSmall': {
              fontSize: 14,
            },
            '.MuiFormLabel-root.Mui-error': {
              color: '#f00 !important',
            },
            '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused, .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink':
              {
                color: hasError ? '#f00 !important' : '#8A42C6 !important',
                fontSize: 16,
              },
            '.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused': {
              fontWeight: '600 !important',
            },
            '.MuiInputLabel-root.MuiInputLabel-shrink': {
              background: 'transparent !important',
            },
            '.MuiInputLabel-outlined:has(+ .MuiOutlinedInput-root > .MuiOutlinedInput-input:-webkit-autofill)': {
              transform: 'translate(14px, -6px) scale(0.75)',
            },
            '.MuiOutlinedInput-input:-webkit-autofill + .MuiOutlinedInput-notchedOutline > legend': {
              maxWidth: '100%',
            },
            ...sx,
          }}
          fullWidth
          size='small'
          value={value || ''}
          {...rest}
        />
      </TooltipWrapper>

      {error_message && (
        <p aria-label='error' className='text-red-primary m-0 pt-1 text-xs' id={`${error_message}-error`}>
          {error_message}
        </p>
      )}
    </>
  );
};
