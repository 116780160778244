'use client';

import { ReactNode } from 'react';

import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { useSettings } from '@paytome.co/mui';

import { AppbarContent } from './appbar';
import MuiLayout from './mui-layout';

interface Props {
  children: ReactNode;
  contentHeightFixed?: boolean;
}

const VerticalNavItems: never[] = [];

export function MuiSidebar({ children, contentHeightFixed }: Props) {
  const { settings, saveSettings } = useSettings();

  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical';
  }

  return (
    <MuiLayout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: VerticalNavItems,

          // Uncomment the below line when using a server-side menu in vertical layout and comment the above line
          // navItems: verticalMenuItems
        },
        appBar: {
          content: (props: { toggleNavVisibility: () => void }) => (
            <AppbarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          ),
        },
      }}
    >
      {children}
    </MuiLayout>
  );
}
