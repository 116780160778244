'use client';

import { ReactNode } from 'react';

import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';

import { SettingsConsumer, SettingsProvider } from '@paytome.co/mui';

import { MuiThemeProvider } from './mui-theme-provider';

export function MuiProvider({ children }: { children: ReactNode }) {
  return (
    <AppRouterCacheProvider>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => <MuiThemeProvider settings={settings}>{children}</MuiThemeProvider>}
        </SettingsConsumer>
      </SettingsProvider>
    </AppRouterCacheProvider>
  );
}
