'use client';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Menu } from '@paytome.co/icon/lucide-react';
import { MuiSettings } from '@paytome.co/type';

import Notifications from './notifications';
import UserDropdown from './user-dropdown';

interface Props {
  hidden: boolean;
  settings: MuiSettings;
  toggleNavVisibility: () => void;
  saveSettings: (values: MuiSettings) => void;
}

export const AppbarContent = (props: Props) => {
  // const { hidden, settings, saveSettings, toggleNavVisibility } = props;
  const { hidden, settings, toggleNavVisibility } = props;

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden && !settings.navHidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <Menu className={'text-indigo-600'} />
          </IconButton>
        ) : null}
        {/*<Autocomplete hidden={hidden} settings={settings} />*/}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        <Notifications settings={settings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};
