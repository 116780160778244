import React from 'react';

import { FormControl, FormControlProps, TextFieldProps } from '@mui/material';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';

import { PrimaryTextField } from '@paytome.co/mui/components';

interface FormControlTextInputProps<TFieldValues extends FieldValues = FieldValues>
  extends Omit<TextFieldProps<'outlined'>, 'variant' | 'name'> {
  control: Control<TFieldValues>;
  name: string;
  errors: FieldErrors<TFieldValues>; // Type-safe errors object
  formRest?: FormControlProps;
  controlRest?: Partial<React.ComponentProps<typeof Controller>>; // Props for Controller
  type?: string;
  label: string;
  placeholder?: string;
  willShowError?: boolean;
}

export const FormControlTextInput = <TFieldValues extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  errors,
  formRest,
  controlRest,
  type = 'text',
  willShowError = true,
  ...rest
}: FormControlTextInputProps<TFieldValues>) => {
  return (
    <FormControl fullWidth {...formRest}>
      <Controller
        name={name}
        control={control as Control}
        render={({ field: { value, onBlur, onChange } }) => (
          <PrimaryTextField
            size='small'
            label={label}
            type={type}
            value={value ?? ''} // Ensure a controlled input
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(errors[name])}
            placeholder={placeholder}
            {...rest}
          />
        )}
        {...controlRest}
      />
      {errors?.[name] && willShowError && (
        <p aria-label='error' className='text-red-primary m-0 pt-1 text-xs' id={`${name}-error`}>
          {errors[name]?.message as string} {/* Ensure error message is rendered as a string */}
        </p>
      )}
    </FormControl>
  );
};
