import React, { Fragment, ReactNode, useState } from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PerfectScrollbarComponent from 'react-perfect-scrollbar';

import { Bell } from '@paytome.co/icon/lucide-react';
import { getInitials } from '@paytome.co/lib';
import { PrimaryAvatar, PrimaryChip } from '@paytome.co/mui/components/ui';
import { KeyValuePairs } from '@paytome.co/type';
import { MuiSettings, ThemeColor } from '@paytome.co/type';

type Notification = {
  title: string;
  subtitle: string;
  meta: string;
  avatarImg?: string;
  avatarAlt?: string;
  avatarIcon?: ReactNode;
  avatarText?: string;
  avatarColor?: ThemeColor;
};

type NotificationDropdownProps = {
  settings: MuiSettings;
};

const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  '& .MuiMenu-list': {
    padding: 0,
  },
}));

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 349,
});

// ** Styled Avatar component
const Avatar = styled(PrimaryAvatar)({
  width: 38,
  height: 38,
  fontSize: '1.125rem',
});

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75),
}));

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const ScrollWrapper = ({ children, hidden }: { children: ReactNode; hidden: boolean }) => {
  if (hidden) {
    return <Box sx={{ maxHeight: 349, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>;
  } else {
    return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>;
  }
};

const notifications: KeyValuePairs[] = [];

export default function Notifications({ settings }: NotificationDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // ** Hook
  const hidden = useMediaQuery((theme: KeyValuePairs) => theme.breakpoints.down('lg'));

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const RenderAvatar: React.FC<{ notification: Notification }> = ({ notification }) => {
    const { avatarAlt, avatarImg, avatarIcon, avatarText, avatarColor = 'primary' } = notification;
    if (avatarImg) {
      return <Avatar alt={avatarAlt} src={avatarImg} />;
    } else if (avatarIcon) {
      return (
        <Avatar skin='light' color={avatarColor}>
          {avatarIcon}
        </Avatar>
      );
    } else {
      return (
        <Avatar skin='light' color={avatarColor}>
          {getInitials(avatarText || '')}
        </Avatar>
      );
    }
  };

  return (
    <Fragment>
      <IconButton
        color='inherit'
        aria-haspopup='true'
        onClick={handleDropdownOpen}
        aria-controls='customized-menu'
        sx={{
          padding: { xs: '6px', lg: '8px' },
        }}
      >
        <Badge
          color='error'
          variant='dot'
          invisible={!notifications.length}
          sx={{
            '& .MuiBadge-badge': {
              top: 4,
              right: 4,
              boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`,
            },
          }}
        >
          <Bell />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ cursor: 'text', fontWeight: 600 }}>Notifications</Typography>
            <PrimaryChip
              rounded
              skin='light'
              size='small'
              color='primary'
              label={`${notifications.length} New`}
              sx={{ fontSize: '0.75rem', fontWeight: 500 }}
            />
          </Box>
        </MenuItem>
        <ScrollWrapper hidden={hidden}>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <MenuItem key={index} onClick={handleDropdownClose}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <RenderAvatar notification={notification} />
                  <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                    <MenuItemTitle>{notification.title}</MenuItemTitle>
                    <MenuItemSubtitle variant='body2'>{notification.subtitle}</MenuItemSubtitle>
                  </Box>
                  <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                    {notification.meta}
                  </Typography>
                </Box>
              </MenuItem>
            ))
          ) : (
            <MenuItem>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                  <MenuItemTitle>No Notifications {'🎉'}</MenuItemTitle>
                  <MenuItemSubtitle variant='body2'>There are no notifications yet!</MenuItemSubtitle>
                </Box>
                <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                  Today
                </Typography>
              </Box>
            </MenuItem>
          )}
        </ScrollWrapper>

        {notifications.length > 0 && (
          <MenuItem
            disableRipple
            disableTouchRipple
            sx={{
              py: 3.5,
              borderBottom: 0,
              cursor: 'default',
              userSelect: 'auto',
              backgroundColor: 'transparent !important',
              borderTop: theme => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Button fullWidth variant='contained' onClick={handleDropdownClose}>
              Read All Notifications
            </Button>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
}
