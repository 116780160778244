const MuiLink = {
  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
};

export default MuiLink;
