'use client';

import { useSearchParams } from 'next/navigation';
import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent, FormControl, IconButton, OutlinedInput } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { Control } from 'react-hook-form/dist/types/form';
import * as yup from 'yup';

import { usePopupAlerts } from '@paytome.co/hooks';
import { CircleX } from '@paytome.co/icon/lucide-react';
import { cn } from '@paytome.co/lib';
import { useAuth } from '@paytome.co/mui/components/auth';
import { Button, CONFIG, goToDirectory } from '@paytome.co/shared';

import '@paytome.co/style/auth/auth.scss';

const schema = yup.object({
  otp_code: yup.string().required('OTP code is required').length(6, 'OTP code must be 6 digits'),
});

type LoginResponse = {
  email?: string;
  password?: string;
  action_name?: string;
  token?: string;
  phone?: string;
};

type SentToInfo = { email?: string; phone?: string };

type Login2faModalProps = {
  show2fa: boolean;
  setShow2fa: (value: boolean) => void;
  sentToInfo: SentToInfo;
  loginResponse: LoginResponse;
};

const ResendMessage = ({
  resend,
  sentToInfo,
}: {
  resend: { animation: boolean; action: boolean };
  sentToInfo: SentToInfo;
}) => (
  <div className='mb-5 gap-1 space-y-2 text-center'>
    <h3 className={cn('font-semibold', { 'resend-text-animation': resend.animation })}>
      {resend.action ? 'We have sent 2FA code again to' : 'We have sent 2FA code to'}
    </h3>
    <div className='mx-auto flex flex-col gap-1 text-left'>
      {sentToInfo?.email && <p className='text-gray-primary text-sm font-semibold'>Email: {sentToInfo.email}</p>}
      {sentToInfo?.phone && <p className='text-gray-primary text-sm font-semibold'>Phone: {sentToInfo.phone}</p>}
    </div>
  </div>
);

const OTPInput = ({
  control,
  errors,
  otpInputOnFocus,
}: {
  control: Control<{ otp_code: string }>;
  errors: FieldErrors<{ otp_code: string }>;
  otpInputOnFocus: () => void;
}) => (
  <FormControl>
    <Controller
      name='otp_code'
      control={control}
      render={({ field: { value, onChange } }) => (
        <OutlinedInput
          placeholder='------'
          size='medium'
          type='text'
          inputProps={{ maxLength: 6 }}
          value={value.replace(/[^0-9]/g, '')}
          onChange={onChange}
          error={Boolean(errors.otp_code)}
          onFocus={otpInputOnFocus}
          sx={{
            width: '290px',
            margin: '0 auto',
            justifyContent: 'center',
            input: {
              fontSize: '16px',
              textAlign: 'center',
              padding: '12px 10px',
            },
            fieldset: {
              borderColor: errors?.otp_code ? '#f00 !important' : '#8A42C6 !important',
              borderWidth: '2px',
              borderRadius: '5px',
            },
          }}
        />
      )}
    />
    {errors?.otp_code?.message && (
      <p aria-label='error' className='text-red-primary m-0 pt-1 text-xs'>
        {errors.otp_code.message}
      </p>
    )}
  </FormControl>
);

export const Login2faModal = ({ show2fa, setShow2fa, loginResponse, sentToInfo }: Login2faModalProps) => {
  const { sendNotification } = usePopupAlerts();
  const [resend, setResend] = useState({ animation: false, action: false });
  const [loading, setLoading] = useState(false);
  const searchParams = useSearchParams();

  const redirectTo = searchParams.get('rt') || CONFIG.defaultRedirectUrl;

  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: { otp_code: '' },
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const { login, verifyOtp } = useAuth();
  const { email, password, action_name, token } = loginResponse;

  const handleVerifyAccount = async (values: { otp_code: string }) => {
    setLoading(true);
    try {
      const res = await verifyOtp.mutateAsync({ otp_code: values.otp_code, action_name, token });
      if (res.statusCode === 200 && res.data.status) {
        goToDirectory(redirectTo);
      } else {
        sendNotification('error', res?.data?.message ?? 'Invalid OTP!');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    setResend(prev => ({ ...prev, animation: false }));
    const res = await login.mutateAsync({ email, password, action_name });
    if (res.statusCode === 301 && res.data.status) {
      reset();
      setResend({ animation: true, action: true });
    }

    if (res.statusCode === 429) {
      sendNotification('error', res?.data?.message ?? '');
    }
  };

  const otpInputOnFocus = () => setResend({ ...resend, animation: false });

  return (
    <Dialog
      open={show2fa}
      maxWidth='xs'
      onClose={() => setShow2fa(false)}
      className='login-modal'
      sx={{
        '.MuiDialog-paper': {
          minWidth: { xs: '320px', sm: '520px' },
          maxWidth: { xs: '350px', sm: '520px' },
        },
      }}
    >
      <div className='relative'>
        <div className='mb-5 border-b-2 border-[#CBD6E1] py-4 text-center'>
          <h2 className='text-xl font-bold capitalize text-black md:text-2xl'>Confirm Your Identity</h2>
          <IconButton
            size='small'
            onClick={() => setShow2fa(false)}
            sx={{ position: 'absolute', top: '14px', right: '27px', svg: { width: '24px', height: '24px' } }}
          >
            <CircleX className='text-red-primary' />
          </IconButton>
        </div>
        <DialogContent sx={{ px: 2, py: 2 }}>
          <form onSubmit={handleSubmit(handleVerifyAccount)} noValidate autoComplete='off'>
            <div className='space-y-5 text-center'>
              <p className='text-gray-primary font-semibold'>Please enter 6 digits 2FA code</p>
              <OTPInput control={control} errors={formState.errors} otpInputOnFocus={otpInputOnFocus} />
            </div>

            <div className='mx-auto my-5 flex flex-col items-center justify-center space-y-5'>
              <Button type='submit' loading={loading} className='h-[48px] min-w-[200px] bg-[#8789FF]'>
                Verify
              </Button>

              <ResendMessage resend={resend} sentToInfo={sentToInfo} />

              <Button
                className={cn('h-[48px] min-w-[200px] bg-[#ECEBFF] text-[#8789FF]', { 'text-white': login.isPending })}
                onClick={handleResendOTP}
                loading={login.isPending}
              >
                Resend Code
              </Button>
            </div>
          </form>
        </DialogContent>
      </div>
    </Dialog>
  );
};
