// ** MUI Imports
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';

import { cn } from '@paytome.co/lib';
import { useBgColor } from '@paytome.co/mui';
// ** Hooks Imports
import { ThemeColor } from '@paytome.co/type';

interface ChipProps extends MuiChipProps {
  skin?: 'light' | 'dark'; // assuming skin prop can be either 'light' or 'dark'
  color?: ThemeColor;
  rounded?: boolean;
}

export const PrimaryChip = (props: ChipProps) => {
  // ** Props
  const { sx, skin, color, rounded } = props;

  // ** Hook
  const bgColors = useBgColor();

  const colorKey = color || 'primary';

  const colors = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  const propsToPass = { ...props };
  propsToPass.rounded = undefined;

  return (
    <MuiChip
      {...propsToPass}
      variant='filled'
      className={cn({
        'MuiChip-rounded': rounded,
        'MuiChip-light': skin === 'light',
      })}
      sx={skin === 'light' && color ? Object.assign(colors[colorKey], sx) : sx}
    />
  );
};
