import * as yup from 'yup';

import { RegularExpressions, SCHEMA_INPUT_LENGTH } from '@paytome.co/shared/constants';

export const transformInput = (value: string): string => value?.replace(/\s+/g, ' ')?.trim() || '';

export const minLengthValidation = (
  value: string | undefined | null,
  limit: number = SCHEMA_INPUT_LENGTH.DEFAULT.MIN
): boolean => !(value && value.length < limit);

export const maxLengthValidation = (
  field = 'This field',
  value: number | string = SCHEMA_INPUT_LENGTH.DEFAULT.MAX
): string => `${field} cannot exceed ${value} characters!`;

export const phoneValidation = (
  yup: typeof import('yup'),
  required = false,
  requiredIfNotEmail = false
): yup.StringSchema<string | undefined> => {
  if (requiredIfNotEmail) {
    return yup.string().when(['$isPhoneInLength', '$isRequired'], {
      is: (isPhoneInLength: boolean, isRequired: boolean) => isPhoneInLength && isRequired,
      then: (schema: yup.StringSchema) =>
        schema
          .required('Phone number is required!')
          .test('min-length', 'Phone number must be greater than 10 characters!', value =>
            minLengthValidation(value, 10)
          ),
      otherwise: (schema: yup.StringSchema) =>
        schema
          .test('min-length', `Phone number must be greater than ${SCHEMA_INPUT_LENGTH.PHONE.MIN} characters!`, value =>
            minLengthValidation(value, SCHEMA_INPUT_LENGTH.PHONE.MIN)
          )
          .required('Phone number is required!'),
    });
  }

  if (required) {
    return yup
      .string()
      .required('Phone number is required!')
      .when('$isPhoneInLength', {
        is: (status: boolean) => status,
        then: (schema: yup.StringSchema) =>
          schema.test('min-length', 'Phone number must be greater than 10 characters!', value =>
            minLengthValidation(value, 10)
          ),
        otherwise: (schema: yup.StringSchema) =>
          schema.test(
            'min-length',
            `Phone number must be greater than ${SCHEMA_INPUT_LENGTH.PHONE.MIN} characters!`,
            value => minLengthValidation(value, SCHEMA_INPUT_LENGTH.PHONE.MIN)
          ),
      });
  }

  return yup.string().when('$isPhoneInLength', {
    is: (status: boolean) => status,
    then: (schema: yup.StringSchema) =>
      schema.test(
        'min-length',
        `Phone number must be greater than ${SCHEMA_INPUT_LENGTH.PHONE.MIN} characters!`,
        value => minLengthValidation(value, SCHEMA_INPUT_LENGTH.PHONE.MIN)
      ),
    otherwise: (schema: yup.StringSchema) =>
      schema.test(
        'min-length',
        `Phone number must be greater than ${SCHEMA_INPUT_LENGTH.PHONE.MIN} characters!`,
        value => minLengthValidation(value, SCHEMA_INPUT_LENGTH.PHONE.MIN)
      ),
  });
};

/* Register Schema */
export const LoginSchema = yup.object().shape({
  email: yup.string().email('The Email address is not valid!').required('Email is required.'),
  password: yup.string().required('Password is required.'),
});

/* Register Schema */
export const RegistrationSchema = yup.object().shape({
  first_name: yup
    .string()
    .max(SCHEMA_INPUT_LENGTH.NAME.MAX, maxLengthValidation('First Name', SCHEMA_INPUT_LENGTH.NAME.MAX))
    .test('min-length', `First Name must be greater than ${SCHEMA_INPUT_LENGTH.NAME.MIN} characters!`, value =>
      minLengthValidation(value, SCHEMA_INPUT_LENGTH.NAME.MIN)
    )
    .required('First Name is required.')
    .matches(
      RegularExpressions.lettersWithSpacesAndDot,
      'Only alphabets, spaces, and dots are allowed in the name field.'
    ),
  last_name: yup
    .string()
    .max(SCHEMA_INPUT_LENGTH.NAME.MAX, maxLengthValidation('Last Name', SCHEMA_INPUT_LENGTH.NAME.MAX))
    .test('min-length', `Last Name must be greater than ${SCHEMA_INPUT_LENGTH.NAME.MIN} characters!`, value =>
      minLengthValidation(value, SCHEMA_INPUT_LENGTH.NAME.MIN)
    )
    .required('Last Name is required.')
    .matches(
      RegularExpressions.lettersWithSpacesAndDot,
      'Only alphabets, spaces, and dots are allowed in the name field.'
    ),
  email: yup
    .string()
    .email('The Email address is not valid!')
    .transform(transformInput)
    .max(SCHEMA_INPUT_LENGTH.EMAIL.MAX, maxLengthValidation('Email', SCHEMA_INPUT_LENGTH.EMAIL.MAX))
    .required('Email is required.'),
  phone: yup
    .string()
    .min(10, 'Phone Number must be greater than 10 characters!')
    .max(15, 'Phone Number cannot exceed 15 characters!')
    .matches(RegularExpressions.phone, 'Phone number is not valid'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters!')
    .required('Password is required.')
    .matches(/[A-Z]/, 'Must contain one uppercase')
    .matches(/([a-z])/, 'Must contain one lowercase')
    .matches(/(\d)/, 'Must contain one number')
    .matches(/(\W)/, 'Must contain one special character'),
  terms: yup.bool().oneOf([true], 'You must accept the privacy policy & terms'),
});

/* Forgot Password Schema */
export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('The Email address is not valid!')
    .transform(transformInput)
    .max(SCHEMA_INPUT_LENGTH.EMAIL.MAX, maxLengthValidation('Email', SCHEMA_INPUT_LENGTH.EMAIL.MAX))
    .required('Email is required.'),
});

export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8)
    .required()
    .matches(/[A-Z]/, 'Must contain one uppercase')
    .matches(/([a-z])/, 'Must contain one lowercase')
    .matches(/(\d)/, 'Must contain one number')
    .matches(/(\W)/, 'Must contain one special character'),

  password_confirmation: yup
    .string()
    .min(8)
    .required()
    .matches(/[A-Z]/, 'Must contain one uppercase')
    .matches(/([a-z])/, 'Must contain one lowercase')
    .matches(/(\d)/, 'Must contain one number')
    .matches(/(\W)/, 'Must contain one special character')
    .oneOf([yup.ref('password'), ''], "Passwords don't match"),
});
